import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { PaginationLinks } from '../../components';
import ListingCardLong from '../ListingCardLong/ListingCardLong';
import css from './SearchResultsPanel.module.css';

const PRIORITY_VEHICLE_IDS = [
  '66f6ecfa-fc07-4acf-a0d3-93f30598aac9',
  '6777efe3-7ea8-452d-b14a-39dffa690496',
  '675a3074-2183-48d5-af04-f48718c11662',
  '6783da32-3379-4c57-aaa6-4cbf8d0f72c3',
  '66f3048c-6ff1-413d-98ec-5171b0644044',
  '66db7c5e-f413-4030-9a70-6afe440cbea3',
  '67251c89-8669-42a2-8e87-e78b6fafb94b',
  '66c8a1c8-2542-4319-a55b-eb3456010ae5',
  '67463314-9b8b-4ca7-b838-dc95f4ac0148',
  '66770fb7-f0cc-4f13-b041-9ee22f98a984',
  '66e1a1ec-4b8f-4c0f-b450-084a6c155ce2',
  '67250e87-408f-4662-a2a0-2eff25dc7643',
  '6643639e-22c0-4664-b242-4cd8eb4f6d42',
  '66a12b60-6bad-4cd6-b11c-06d9a360be67',
  '66be2511-69fd-461e-841e-5685cc7cf973',
  '675db86e-ee07-40ad-a149-3069074d8da7',
  '6712850e-a58b-46c1-ac5a-5db6261d36ce',
  '67835a81-5007-48bc-ae32-e7554efa57a2',
  '66edff15-029f-4985-90b7-0dd5c29aa0e3',
  '67aa7a34-849b-4dcf-829b-1362ab5659e3',
];

const SearchResultsPanel = props => {
  const { className, rootClassName, listings, pagination, search, setActiveListing } = props;
  const classes = classNames(rootClassName || css.root, className);

  // Sort listings to prioritize vehicles
  const sortedListings = React.useMemo(() => {
    const priorityListings = [];
    const regularListings = [];

    listings.forEach(listing => {
      if (PRIORITY_VEHICLE_IDS.includes(listing.id.uuid)) {
        priorityListings.push(listing);
      } else {
        regularListings.push(listing);
      }
    });

    return [...priorityListings, ...regularListings];
  }, [listings]);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  return (
    <div className={classes}>
      <div className={css.listingCards}>
        {sortedListings.map(l => (
          <ListingCardLong
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes}
            setActiveListing={setActiveListing}
          />
        ))}
        {props.children}
      </div>
      {paginationLinks}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;
