import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import arrayMutators from 'final-form-arrays';
import { composeValidators, required } from '../../util/validators';
import { Form, Button, FieldSelect, FieldCheckboxGroup, FieldTextInput } from '../../components';
import css from './EditListingPoliciesForm.module.css';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

const delivery = [
  { key: 'yes', value: 'Si' },
  { key: 'no', value: 'No' },
];

export const EditListingPoliciesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        name,
        invalid,
        fieldId,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        formId,
        values,
      } = formRenderProps;

      const minimumTripOption = config.custom.minimumTripDays;
      const maximumTripOption = config.custom.maximumTripDays;
      const options = findOptionsForSelectFilter('carrules', filterConfig);
      const visibleOptions = options.filter(option => option.key !== 'no_rules');
      // minimum trip
      const minimumTripLabel = intl.formatMessage({ id: 'EditListingCarRulesForm.minimumTrip' });
      const minimumTripPlaceholder = intl.formatMessage({
        id: 'EditListingCarRulesForm.minimumTripPlaceholder',
      });

      // maximum trip
      const maximumTripLabel = intl.formatMessage({ id: 'EditListingCarRulesForm.maximumTrip' });
      const maximumTripPlaceholderMessage = intl.formatMessage({
        id: 'EditListingCarRulesForm.maximumTripPlaceholder',
      });

      const noticPeriodLabel = intl.formatMessage({
        id: 'EditListingPricingForm.noticePeriodInputLabel',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;
      const instantBookingOptions = [
        { key: 'yes', label: 'Si' },
        { key: 'No', label: 'No' },
      ];

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <div>
            <div className={css.rowBox}>
              <FieldSelect
                id={`${fieldId}.minimumTrip`}
                name="minimumTrip"
                className={css.inputBox}
                label={minimumTripLabel}
                placeholder={minimumTripPlaceholder}
              >
                <option value="">Por favor, selecciona la opción</option>
                {minimumTripOption.map((item, index) => (
                  <option key={item.key} value={item.key}>
                    {item.label}
                  </option>
                ))}
              </FieldSelect>

              <FieldSelect
                id={`${fieldId}.maximumTrip`}
                name="maximumTrip"
                className={css.inputBox}
                label={maximumTripLabel}
                placeholder={maximumTripPlaceholderMessage}
              >
                <option value="">Por favor, selecciona la opción</option>
                {maximumTripOption.map((item, index) => (
                  <option key={item.key} value={item.key}>
                    {item.label}
                  </option>
                ))}
              </FieldSelect>
            </div>

            <div className={css.rowBox}>
              <FieldSelect
                className={css.inputBox}
                name={'instantBooking'}
                id={'instantBooking'}
                label={'Reserva instantánea?'}
                validate={composeValidators(required('Necesitas seleccionar una opción'))}
              >
                <option disabled value="">
                  {'Selecciona una opción'}
                </option>
                {instantBookingOptions.map(c => (
                  <option key={c.key} value={c.key}>
                    {c.label}
                  </option>
                ))}
              </FieldSelect>

              <FieldTextInput
                className={css.inputBox}
                type="number"
                id={formId ? `${formId}.noticPeriod` : 'noticPeriod'}
                name="noticPeriod"
                label={noticPeriodLabel}
                placeholder="Período de aviso"
              />
            </div>

            <div className={css.rowBox}>
              <FieldSelect
                className={css.inputBox}
                name={'homeDelivery'}
                id={'homeDelivery'}
                label={'Home Delivery'}
                validate={composeValidators(required('Necesitas seleccionar una opción'))}
              >
                <option disabled value="">
                  {'Selecciona una opción'}
                </option>
                {delivery.map(c => (
                  <option key={c.key} value={c.key}>
                    {c.value}
                  </option>
                ))}
              </FieldSelect>
            </div>

            {values?.instantBooking === 'yes' && values?.noticPeriod < 4 ? (
              <p style={{ color: 'red' }}>Por favor, añade un período de aviso de más de 4 horas</p>
            ) : null}

            <div className={css.box}>
              <div className={css.sectionTitle}>
                <h3>Reglas del vehículo (opcional)</h3>
                <p className={css.optionalText}>
                  Puedes seleccionar las reglas que apliquen a tu vehículo
                </p>
              </div>
              <FieldCheckboxGroup
                className={css.features}
                id={`${fieldId}.carrules`}
                name="carrules"
                options={visibleOptions}
                defaultValue={['no_rules']}
              />
            </div>

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingPoliciesFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
  filterConfig: config.custom.filters,
};

EditListingPoliciesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPoliciesFormComponent);
