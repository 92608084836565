import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';

import { Button, Form, FieldTextInput, FieldSelect } from '../../components';
import css from './EditListingOneDayPriceForm.module.css';

const validate = values => {
  const errors = {};

  if (values.isOneDayPrice === 'yes') {
    if (!values.oneDayPrice || values.oneDayPrice <= 0) {
      errors.oneDayPrice = 'Campo requerido y debe ser mayor a 0';
    }
  }

  return errors;
};

export const EditListingOneDayPriceFormComponent = props => (
  <FinalForm
    {...props}
    validate={validate}
    render={formRenderProps => {
      const {
        form,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        formId,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const isOneDayPrice = formRenderProps.values.isOneDayPrice;

      React.useEffect(() => {
        if (isOneDayPrice === 'no') {
          form.change('oneDayPrice', null);
        }
      }, [isOneDayPrice, form]);

      const oneDayPrice = intl.formatMessage({
        id: 'EditListingOneDayPriceForm.oneDayPriceInputLabel',
      });

      const oneDayPriceOptions = [
        { key: 'yes', label: 'Si' },
        { key: 'no', label: 'No' },
      ];

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}
          <h2 className={css.oneDayPriceDescription}>
            Las rentas de corto plazo no solo son una excelente manera de atraer más clientes, sino
            que también pueden hacer que tu vehículo sea más rentable. Al ofrecer alquileres express
            de 1 y 2 días, tienes la oportunidad de incrementar tu tarifa y ganar entre $10 y $20
            adicionales por día en comparación con rentas de mayor duración.
          </h2>

          <div className={css.rowBox}>
            <FieldSelect
              className={css.inputBox}
              id={formId ? `${formId}.isOneDayPrice` : 'isOneDayPrice'}
              name="isOneDayPrice"
              label={oneDayPrice}
              placeholder="Por favor, selecciona la opción"
            >
              <option disabled value="">
                {'Selecciona una opción'}
              </option>
              {oneDayPriceOptions.map(c => (
                <option key={c.key} value={c.key}>
                  {c.label}
                </option>
              ))}
            </FieldSelect>

            {isOneDayPrice === 'yes' && (
              <FieldTextInput
                className={css.inputBox}
                type="number"
                id={formId ? `${formId}.oneDayPrice` : 'oneDayPrice'}
                name="oneDayPrice"
                label={oneDayPrice}
                placeholder="0 $"
                min="0"
              />
            )}
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingOneDayPriceFormComponent.defaultProps = { fetchErrors: null };

EditListingOneDayPriceFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingOneDayPriceFormComponent);
