import { useState, useEffect } from 'react';
import Modal from '../Modal/Modal';
import css from './PromotionalBanner.module.css';

const PromotionalBannerModal = () => {
  const [open, setOpen] = useState(true);

  // Variables de texto y enlaces para facilitar edición
  const message = "Reserva con un 25% y garantiza el mejor precio.";
  const whatsappNumber = "8296575069";
  const whatsappText = "Hola, me gustaría reservar con solo un 25%.";
  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappText)}`;
  const whatsappIconSrc = "/static/media/whatsapp-icon.150c7451.svg";
  const noShowText = "No volver a mostrar";
  const contactText = "Contáctanos vía WhatsApp";

  // Verifica si se debe mostrar el modal basándose en la fecha almacenada
  const shouldShowModal = (declinedDate) => {
    if (!declinedDate) return true;
    const now = new Date();
    const oneDayInMs = 24 * 60 * 60 * 1000;
    return now - new Date(declinedDate) > oneDayInMs;
  };

  const onCloseModal = () => setOpen(false);

  const handleNoShow = () => {
    const now = new Date().toISOString(); // Almacenar como string ISO
    localStorage.setItem('declinedDate', now);
    setOpen(false);
  };

  useEffect(() => {
    const storedDeclinedDate = localStorage.getItem('declinedDate');
    setOpen(shouldShowModal(storedDeclinedDate));
  }, []);

  return (
    <Modal
      isOpen={open}
      id="promotional-banner"
      onManageDisableScrolling={() => {}}
      onClose={onCloseModal}
    >
      <div className={css.promotionalBannerModalContent}>
        <p className={css.promotionalBannerModalDescription}>{message}</p>
        <div className={css.promotionalBannerModalButton}>
          <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
            {contactText}
            <img src={whatsappIconSrc} alt="WhatsApp" />
          </a>
        </div>
        <div className={css.promotionalBannerModalNoShow}>
          <a onClick={handleNoShow}>{noShowText}</a>
        </div>
      </div>
    </Modal>
  );
};

export default PromotionalBannerModal;
